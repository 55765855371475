import React from "react";
import Layout from "../../components/layout";
import { StaticImage } from "gatsby-plugin-image";

export default function CatFacts() {
  return (
    <Layout
      pageTitle="Privacy Policy for Cat Facts App"
      description="Cat Facts application"
      keywords="cat, facts, cat facts"
    >
      <a href="https://play.google.com/store/apps/details?id=com.danielsilvajobs.factsservicecat">
        <StaticImage
          src="../../shared/images/facts.png"
          layout="fixed"
          width={200}
          height={200}
          alt="Facts image with link to the Cat Facts app"
        ></StaticImage>
      </a>
      Thank you for using the Cat Facts app ("the App"). This Privacy Policy
      outlines how the App collects, uses, and protects any information provided
      by you while using the App. We are committed to ensuring that your privacy
      is protected. Please read this Privacy Policy carefully to understand how
      we handle your information.
      <h2 className="pb-3 pt-3">Information Collection</h2>
      The Cat Facts app does not collect any personal information from its
      users. We do not require you to provide any personally identifiable
      information (such as your name, email address, or phone number) to use the
      App. We do not use cookies or any other tracking technologies to gather
      information about your activities.
      <h2 className="pb-3 pt-3">Use of Information</h2>
      Since no personal information is collected, we do not use or share any
      information about you with third parties. The App is designed to provide a
      fun and educational experience, allowing you to enjoy random cat facts
      without compromising your privacy.
      <h2 className="pb-3 pt-3">Security</h2>
      We prioritize the security of your information. As we do not collect any
      personal data, there is no risk of unauthorized access, disclosure, or
      alteration of your information within the App.
      <h2 className="pb-3 pt-3">Links to Third-Party Websites</h2>
      The Cat Facts app may contain links to third-party websites for additional
      resources or information. However, we have no control over these websites
      and cannot be responsible for the protection and privacy of any
      information you provide while visiting them. We recommend exercising
      caution and reviewing the privacy policies of any third-party websites
      before providing any personal information.
      <h2 className="pb-3 pt-3">Children's Privacy</h2>
      We do not knowingly collect personal information from children. If you are
      a parent or guardian and believe that your child may have provided
      personal information through the App, please contact us immediately, and
      we will take steps to remove such information from our systems.
      <h2 className="pb-3 pt-3">Changes to the Privacy Policy</h2>
      We may update this Privacy Policy from time to time to reflect changes in
      our practices or legal requirements. The most current version of the
      Privacy Policy will be posted on this page, and the revised date will be
      indicated at the top. It is your responsibility to review this Privacy
      Policy periodically to stay informed about how we protect your
      information. By using the Cat Facts app, you acknowledge and agree to the
      terms outlined in this Privacy Policy.
      <br></br>
      <br></br>
    </Layout>
  );
}
